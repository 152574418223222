import React, { useEffect, useState } from "react";
import { Button, ConfirmationModal } from "../../../../../../components";
import ProjectFilesFilter from "./filter-box";
import ProjectFilesListing from "./files-listing";
import { useSelector } from "react-redux";
import { CustomDispatch } from "../../../../../../helpers";
import {
  deleteMediaRequest,
  getProjectMediaRequest,
  updateMediaRequest,
} from "../../../../../../redux/slicers/project";
import {
  cloneDeepItem,
  convertObjectToFormData,
  documentValidation,
  toastAlert,
} from "../../../../../../utils";
import {
  generateUploadURLRequest,
  uploadMediaRequest,
} from "../../../../../../redux/slicers/general";
import { ALERT_TYPES, TOAST_MESSAGES } from "../../../../../../constants";
import {
  manipulateAutoUpdateMediaData,
  manipulateUpdateMediaPayload,
} from "../../../../../../data-manipulator/project";
import "./styles.scss";

const ProjectFiles = ({
  mediaLoader,
  allMedia,
  media,
  setMedia,
  getUploadedFiles,
}) => {
  // STATES
  const [uploadLoader, setUploadLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredMedia, setFilteredMedia] = useState([]);
  const [deletedMedia, setDeletedMedia] = useState([]);
  const [savePreview, setsavePreview] = useState(false);
  const [deleteFilesPreview, setdeleteFilesPreview] = useState(false);
  const [uploadedFiles, setuploadedFiles] = useState(0);
  const [totalFiles, settotalFiles] = useState(0);

  // REDUX DATA
  const data = useSelector(({ project }) => project.selectedProject);
  const projectTags = useSelector(({ project }) => project.projectTags);

  // CUSTOM DISPATCH
  const [getProjectMediaInterval] = CustomDispatch(getProjectMediaRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);
  const [generateUrl] = CustomDispatch(generateUploadURLRequest);
  const [deleteMedia, deleteLoader] = CustomDispatch(deleteMediaRequest);
  const [updateMedia, updateLoader] = CustomDispatch(updateMediaRequest);

  // CONST VALS
  const deleteButtonPreview = filteredMedia.some((item) => item.selected);
  const deleteAllLoader = deleteLoader && deleteFilesPreview;
  const deleteFileLoader = deleteLoader && !deleteFilesPreview;

  // HELPERS
  const getProjectMediaHelper = (clearHandler = () => {}) => {
    try {
      const payload = {
        resource: "data",
        method: "list",
        details: {
          project_id: data.id,
          db_type: data.database,
        },
      };
      getProjectMediaInterval({
        payload,
        onlyResponse: true,
        success(res) {
          const tempMedia = [...media];
          tempMedia.forEach((item, index) => {
            const newdata = res?.media?.files.findIndex(
              (x) => x.name === item.name
            );
            if (newdata !== -1) {
              tempMedia[index] = manipulateAutoUpdateMediaData(
                tempMedia[index],
                res?.media?.files[newdata]
              );
            }
          });
          setMedia(tempMedia);
          const isPending = res?.media?.files.some((item) => item.isPending);
          if (!isPending) clearHandler();
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const uploadMediaHelper = (data, files) => {
    settotalFiles(data.length);
    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        url: filedata.url,
        payload,
        callback: (status) => setsavePreview(!status),
        success: () => setuploadedFiles((uploadedFiles) => uploadedFiles + 1),
      });
    });
  };

  const generateUrlHelper = (files = []) => {
    const payload = {
      resource: "data",
      method: "upload",
      details: {
        project_id: data?.id,
        db_type: data?.database,
        files: files.map((item) => manipulateUpdateMediaPayload(item)),
      },
    };
    setUploadLoader(true);
    generateUrl({
      payload,
      callback: (status) => setsavePreview(!status),
      success: (data) => {
        uploadMediaHelper(
          data.files,
          files.map((item) => item.file)
        );
      },
      error: () => setUploadLoader(false),
    });
  };

  const deleteMediaHelper = (toastPreview, media, onSuccess = () => {}) => {
    const payloadMedia = media ?? deletedMedia;
    const payload = {
      resource: "data",
      method: "delete",
      details: {
        project_id: data.id,
        db_type: data.database,
        files: payloadMedia.map((x) => {
          const item = allMedia.find((y) => y.name === x);
          return manipulateUpdateMediaPayload(item);
        }),
      },
    };
    deleteMedia({
      payload,
      hideToast: !toastPreview,
      callback: (status) => !media && setsavePreview(!status),
      success: () => {
        setDeletedMedia([]);
        onSuccess();
      },
    });
  };

  const updateMediaHelper = () => {
    const files = media.filter((item) => item.isUpdated);
    if (files.length < 1) return;
    const details = {
      project_id: data?.id,
      db_type: data?.database,
      files: files.map((item) => manipulateUpdateMediaPayload(item)),
    };
    const payload = {
      resource: "data",
      method: "update",
      details,
    };
    updateMedia({
      payload,
      callback: (status) => setsavePreview(!status),
    });
  };

  // HANDLERS
  const setsavePreviewHandler = () => setsavePreview(true);

  const deleteFilesPreviewHandler = () =>
    setdeleteFilesPreview(!deleteFilesPreview);

  const selectUploadMediaHandler = (e) => {
    const files = e.target?.files ?? [];
    const validFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const isExist = media.find((item) => item.name === file.name);
      const isVaid = documentValidation(file);

      if (isExist) toastAlert(TOAST_MESSAGES.FILE_EXIST, ALERT_TYPES.ERROR);

      if (!isExist && isVaid) {
        const object = {
          file: file,
          name: file.name,
          exposed: false,
          referenced: true,
          tags: [],
        };
        validFiles.push(object);
        // setsavePreviewHandler();
      }
    }
    if (validFiles.length >= 1) generateUrlHelper(validFiles);
  };

  const searchTextHandler = (text) => {
    setSearchText(text);
  };

  const selectTagHandler = (tag) => {
    const temp = [...selectedTags];
    const index = temp.findIndex((item) => item === tag);
    if (index !== -1) temp.splice(index, 1);
    else temp.push(tag);
    setSelectedTags(temp);
  };

  const selectFilterHandler = (filter) => {
    const temp = [...selectedFilters];
    const index = temp.findIndex((item) => item === filter);
    if (index !== -1) temp.splice(index, 1);
    else temp.push(filter);
    setSelectedFilters(temp);
  };

  const deleteMediaHandler = (file, onSucess) => {
    const temp = [...media];
    const mediaIndex = temp.findIndex((item) => item.name === file.name);
    if (mediaIndex !== -1) temp.splice(mediaIndex, 1);
    deleteMediaHelper(true, [file.name], () => {
      setMedia(temp);
      onSucess();
    });
  };

  const deleteSelectedMediaHandler = () => {
    const temp = [...media];
    const filtered = temp.filter((item) => !item.selected);
    const deletemedia = temp.filter((item) => item.selected && !item.isCreated);
    if (deletemedia.length) {
      deleteMediaHelper(
        true,
        deletemedia.map((x) => x.name),
        () => {
          setMedia(filtered);
          setdeleteFilesPreview(false);
        }
      );
      return;
    }
    setMedia(filtered);
    setdeleteFilesPreview(false);
  };

  const saveChangesHandler = () => {
    const updateMedia = media.filter((item) => item.isUpdated).length > 0;
    if (deletedMedia.length > 0) deleteMediaHelper(!updateMedia);
    if (updateMedia) updateMediaHelper();
  };

  // HOOKS
  useEffect(() => {
    if (!media.length) return setFilteredMedia([]);
    let temp = cloneDeepItem(media);
    if (searchText) {
      temp = temp.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (selectedTags.length) {
      temp = temp.filter((item) =>
        item.tags.some((tag) => selectedTags.includes(tag))
      );
    }
    if (selectedFilters.length) {
      temp = temp.filter((item) =>
        selectedFilters.every((filter) => item[filter])
      );
    }
    setFilteredMedia(temp);
  }, [media, searchText, selectedTags, selectedFilters]);

  useEffect(() => {
    if (uploadedFiles >= 1 && uploadedFiles >= totalFiles) {
      setuploadedFiles(0);
      setUploadLoader(false);
      getUploadedFiles();
      toastAlert(TOAST_MESSAGES.CHANGES_SAVED);
    }
  }, [uploadedFiles, totalFiles]);

  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const pendingmedia = media?.filter((item) => item.isPending).length;
      if (pendingmedia > 0) {
        interval = setInterval(() => {
          getProjectMediaHelper(() => {
            clearInterval(interval);
          });
        }, 10000); // 10 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [media]);

  return (
    <div className="project-files">
      <h4 className="sub-title">Files</h4>
      <div className="uploader">
        <Button
          invertedTheme
          boldText
          className="upload-btn"
          loaderColor="#000"
          isLoading={uploadLoader}
        >
          <input type="file" multiple onChange={selectUploadMediaHandler} />
          Upload files
        </Button>
        <p>Supported types: pdf, doc(x), and txt</p>
      </div>
      <ProjectFilesFilter
        tags={projectTags.files}
        searchText={searchText}
        searchHandler={searchTextHandler}
        selectedTags={selectedTags}
        selectTag={selectTagHandler}
        selectedFilters={selectedFilters}
        selectFilter={selectFilterHandler}
      />
      <ProjectFilesListing
        isLoading={mediaLoader}
        files={filteredMedia}
        deleteLoader={deleteFileLoader}
        setFile={setMedia}
        deleteFile={deleteMediaHandler}
        enableSavePreview={setsavePreviewHandler}
      />
      <div className="action-box">
        <Button
          boldText
          className="delete-btn"
          onClick={deleteFilesPreviewHandler}
          disabled={!deleteButtonPreview || deleteAllLoader}
        >
          Delete selected files
        </Button>
        <Button
          boldText
          invertedTheme
          disabled={!savePreview}
          isLoading={updateLoader}
          onClick={saveChangesHandler}
          className="save-btn"
        >
          Save changes
        </Button>
      </div>
      {/* DELETE MEDIA MODAL */}
      <ConfirmationModal
        title="Delete Files"
        description="Are you sure you want to delete selected files?"
        preview={deleteFilesPreview}
        isLoading={deleteAllLoader}
        previewHandler={deleteFilesPreviewHandler}
        confirmBtnHandler={deleteSelectedMediaHandler}
      />
    </div>
  );
};

export default ProjectFiles;
