import React, { useCallback, useRef, useState } from "react";
import { Form, Tooltip } from "antd";
import { Button } from "../../../../../components";
// import { CHAT_OPTIONS } from "../../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faFile,
  // faMicrophone,
  faPaperclip,
  faSquare,
  // faStop,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
// import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { promptFilesValidation, toastAlert } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useSelector } from "react-redux";
import { ALERT_TYPES } from "../../../../../constants";
import "./styles.scss";
import clsx from "clsx";
import { BounceLoader } from "react-spinners";

const InputBox = React.forwardRef(
  (
    {
      selectedProject,
      sendMessage,
      stopResponse,
      isLoading,
      chatOption,
      setChatOption,
      removeSelectedFile,
      selectFile,
      selectedFile = [],
      fileLoader,
    },
    ref
  ) => {
    // STATES
    // const [chatOptionPreview, setChatOptionPreview] = useState(false);
    const [message, setMessage] = useState("");

    // REDUX DATA
    const fieldsView = useSelector(({ project }) => project.fieldsVisibility);
    const fieldsValues = useSelector((state) => state.project.fieldsValues);

    // CONST VALS
    const [form] = Form.useForm();
    const uploadFileInput = useRef(null);
    const isFilesLoading = Object.values(fileLoader).length !== 0;
    // const chatOptions = CHAT_OPTIONS.filter((x) => x.value !== chatOption.value);
    const {
      // transcript,
      listening,
      resetTranscript,
      // browserSupportsSpeechRecognition,
      // browserSupportsContinuousListening,
    } = useSpeechRecognition();
    const inputPlaceholder = listening
      ? "Listening..."
      : fieldsView.input
      ? fieldsValues.input
      : "";

    // HANDLERS
    // const recordingHandler = () => {
    //   if (listening) {
    //     SpeechRecognition.stopListening();
    //     resetTranscript();
    //     return;
    //   }
    //   setMessage("");
    //   SpeechRecognition.startListening({
    //     continuous: browserSupportsContinuousListening,
    //   });
    // };
    const uploadFileHandler = (e) => {
      const files = e.target.files;
      let uploadedFiles = [];
      if (!files) return;

      const filesArray = Array.from(files);
      const totalfiles = selectedFile.length + filesArray.length;

      if (totalfiles > 5)
        toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

      for (const file of filesArray) {
        const isExist = [...selectedFile, ...uploadedFiles].find(
          (item) => item.name === file.name
        );
        if (isExist) {
          toastAlert(
            `${file.name} is already uploaded. Please upload a different file.`,
            ALERT_TYPES.ERROR
          );
        } else {
          if (promptFilesValidation(file)) {
            uploadedFiles.push(file);
            if (uploadedFiles.length >= 5) break;
          }
        }
      }

      selectFile(uploadedFiles);
      if (uploadFileInput.current) uploadFileInput.current.value = "";
    };
    // const setSelectedChatOptionHandler = (item) => {
    //   setChatOption(item);
    //   setChatOptionPreview(false);
    // };
    // const setChatOptionPreviewHandler = () => {
    //   setChatOptionPreview(!chatOptionPreview);
    // };
    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      if (isLoading) return stopResponse();
      if (message.trim() === "") return;
      if (isFilesLoading) return;

      setMessage("");
      sendMessage(message, selectedFile);
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "20px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedFile.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedFile]
    );

    // useEffect(() => {
    //   console.log("transcript", transcript);
    //   if (transcript !== "") {
    //     setMessage(transcript);
    //   }
    // }, [transcript]);

    // useEffect(() => {
    //   // function to close chat option preview on outside click
    //   document.addEventListener("click", (e) => {
    //     if (
    //       !e.target.closest(".chat-selector") &&
    //       !e.target.closest(".chat-options")
    //     ) {
    //       setChatOptionPreview(false);
    //     }
    //   });
    // }, []);

    return (
      <div className="inputbox-wrapper">
        <div className="input-box">
          <Form className="input-form" form={form}>
            <label htmlFor="file-uploader" className="upload-button">
              Upload File
              <input
                type="file"
                multiple
                ref={uploadFileInput}
                // disabled={fileLoading}
                id="file-uploader"
                onChange={uploadFileHandler}
                accept=".png, .jpg, .jpeg, .pdf, .docx, .txt, image/jpg, image/jpeg, image/png"
              />
              <Tooltip
                overlayInnerStyle={{
                  width: 400,
                }}
                title={
                  <>
                    Upload a document or image to use in this chat session. The
                    file will only be available in this conversation and will
                    not carry over to new chats. Unlike the Knowledge Base,
                    where media is stored persistently, uploaded files here are
                    temporary and are cleared when the chat ends.
                    <br />
                    <br />
                    Allowed formats: PDF, DOCX, TXT, JPG, PNG
                  </>
                }
                placement="topLeft"
              >
                <FontAwesomeIcon className="upload-icon" icon={faPaperclip} />
              </Tooltip>
            </label>
            {/* PROMPT GENERATOR */}
            <textarea
              id="input-box"
              className="ant-input"
              ref={inputRef}
              placeholder={inputPlaceholder}
              value={message}
              onChange={setMessageHandler}
              onKeyDown={handleKeyPress}
            />
            {/* <Tooltip
              title={
                !browserSupportsSpeechRecognition
                  ? "Browser doesn't support speech recognition"
                  : listening
                  ? "Stop microphone"
                  : "Use microphone"
              }
            >
              <button
                className="upload-button"
                onClick={recordingHandler}
                disabled={!browserSupportsSpeechRecognition}
              >
                <FontAwesomeIcon
                  className="upload-icon"
                  icon={listening ? faStop : faMicrophone}
                />
              </button>
            </Tooltip> */}
            {/* PROMPT OPTION */}
            {/* <div
          className={`chat-selector ml-5 ${chatOptionPreview ? "active" : ""}`}>
          <button
            type="button"
            className="selected-chat"
            onClick={setChatOptionPreviewHandler}>
            <span>{chatOption.label}</span>
            <FontAwesomeIcon className="select-toggle" icon={faChevronDown} />
          </button>
          <ul className="chat-options">
            {chatOptions.map((item, index) => (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => setSelectedChatOptionHandler(item)}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div> */}
            {/* GENERATE BUTTON */}
            <Button
              type="submit"
              className="send-btn"
              invertedTheme
              boldText
              onClick={generateMessageHandler}
              disabled={(message.trim() === "" || isFilesLoading) && !isLoading}
            >
              {/* <span>{generateBtnText}</span> */}
              <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
            </Button>
          </Form>
          <div className="images-box">
            <div className="selected-images">
              {selectedFile.map((item, index) => {
                const isFileLoading = fileLoader[item.name];
                const isImage = item.type === "image";
                return (
                  <div
                    className={clsx("media-box", isImage ? "image" : "file")}
                  >
                    {isFileLoading && (
                      <div className="media-progress">
                        <BounceLoader color="#fff" size={15} />
                      </div>
                    )}
                    <span
                      role="button"
                      tabIndex={index}
                      className="delete-btn"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") removeSelectedFile(item.name);
                      }}
                      onClick={() => removeSelectedFile(item.name)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </span>
                    {isImage && <img src={item.data} alt={item.name} />}
                    {!isImage && (
                      <>
                        <div className="thumb">
                          <FontAwesomeIcon icon={faFile} />
                        </div>
                        <div className="detail">
                          <h4>{item.name}</h4>
                          <p>Document</p>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* PROMPT TAGLINE */}
        <div
          className="tagline"
          style={{ opacity: fieldsView.desclaimer ? 1 : 0 }}
        >
          {fieldsValues.desclaimer}
          {/* <span className="ml-5">Report an issue.</span> */}
        </div>
      </div>
    );
  }
);

export default InputBox;
