import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { Tooltip } from "antd";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import "katex/dist/katex.min.css";
import "./styles.scss";

const MarkdownSnippetHeader = ({ codeText, match }) => {
  // STATES
  const [isCopied, setCopied] = useState(false);

  // HANDLERS
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="code-header">
      <span className="language-tag">{match[1]}</span>
      <Tooltip title={isCopied ? "Copied" : "Copy snippet"}>
        <CopyToClipboard text={codeText} onCopy={handleCopy}>
          <button className={clsx(isCopied && "active")}>
            <FontAwesomeIcon icon={isCopied ? faCircleCheck : faCopy} />
          </button>
        </CopyToClipboard>
      </Tooltip>
    </div>
  );
};

const Markdown = ({ children }) => {
  // CONST VALS
  const customStyle = {
    ...vs,
    'pre[class*="language-"]': {
      ...vs['pre[class*="language-"]'],
      background: "#e5e7eb",
      margin: 0,
      padding: "1em",
      fontSize: "1em",
      fontFamily: '"Fira Code", Monaco, Consolas, "Courier New", monospace',
    },
    'code[class*="language-"]': {
      ...vs['code[class*="language-"]'],
      background: "#e5e7eb",
      padding: 0,
      fontSize: "1em",
      fontFamily: '"Fira Code", Monaco, Consolas, "Courier New", monospace',
    },
  };

  return (
    <ReactMarkdown
      className="markdown-body"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeKatex]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          const codeText = String(children).replace(/\n$/, "");
          if (!inline && match) {
            return (
              <div className="code-block-wrapper">
                <MarkdownSnippetHeader codeText={codeText} match={match} />
                <SyntaxHighlighter
                  style={customStyle}
                  language={match[1]}
                  PreTag="div"
                  customStyle={{
                    background: "#e5e7eb",
                    margin: 0,
                    padding: "1em",
                  }}
                  {...props}
                >
                  {codeText}
                </SyntaxHighlighter>
              </div>
            );
          }

          return (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {children.trim()}
    </ReactMarkdown>
  );
};

export default Markdown;
