import React, { useState, useMemo, useRef, useEffect } from "react";
import { InputBox, MessageBox } from "..";
import { useDispatch, useSelector } from "react-redux";
import { WebSocketHandlers } from "../../../../../services/web-sockets";
import { Loader } from "../../../../../components";
import {
  createChatRequest,
  getMessagesRequest,
  recieveMessageRequest,
  sendMessageRequest,
  clearMessages,
} from "../../../../../redux/slicers/chat";
import {
  CHAT_OPTIONS,
  MESSAGE_TIMEOUT,
  MESSAGE_TIMEOUT_ERROR,
  MESSAGE_TYPE_JSON,
} from "../../../../../constants";
// import { navigateToLogin } from "../../../../../utils";
import { Helmet } from "react-helmet";
import { Images } from "../../../../../theme";
import SplashScreen from "../splash-screen";
import { CustomDispatch } from "../../../../../helpers";
import { useCallback } from "react";
import { useLayoutEffect } from "react";
import clsx from "clsx";
import "./styles.scss";
import { useSearchParams } from "react-router-dom";
import {
  deleteUploadMediaRequest,
  generatePromptUploadURLRequest,
  getMediaStatusRequest,
  uploadMediaRequest,
} from "../../../../../redux/slicers/general";
import { convertObjectToFormData, generateGuid } from "../../../../../utils";

const ChatBox = () => {
  // STATES
  const [sessionId, setSessionId] = useState(null);
  const [reversedScrolling, setReversedScrolling] = useState(false);
  const [initLoad, setinitLoad] = useState(true);
  const [scrollToKey, setScrollToKey] = useState();
  const [moreMessagessData, setmoreMessagesData] = useState(null);
  const [selectedChatOption, setselectedChatOption] = useState(CHAT_OPTIONS[0]);
  const [isLoading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileLoading, setFileLoading] = useState({});

  // CUSTOM DISPATCH
  const [createChat, chatLoader] = CustomDispatch(createChatRequest);
  const [getMessages, messgesLoader] = CustomDispatch(getMessagesRequest);
  const [uploadMedia] = CustomDispatch(uploadMediaRequest);
  const [generateUrl] = CustomDispatch(generatePromptUploadURLRequest);
  const [getMediaStatus] = CustomDispatch(getMediaStatusRequest);
  const [deleteMedia] = CustomDispatch(deleteUploadMediaRequest);

  // REDUX DATA
  const selectedProject = useSelector((state) => state.project.selectedProject);
  const { messages } = useSelector((state) => state.chat);

  // CONST VALS
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const messageBox = useRef(null);
  const messageHistory = useRef(null);
  const toastTimerRef = useRef(null);
  const messageWrapper = useRef(null);
  const itemRefs = useRef({});
  const selectedChat = searchParams.get("chat");
  const { sendJsonMessage, lastMessage, getWebSocket } = WebSocketHandlers();

  // HELPERS
  const getUploadedMediaStatus = () => {
    const payload = {
      resource: "data",
      method: "list_assets",
      details: {
        session_id: selectedChat ?? sessionId,
        project_id: selectedProject.id,
        db_type: "opensearch",
      },
    };
    if (selectedQuery) payload.details["query_id"] = selectedQuery;
    getMediaStatus({
      payload,
      success: ({ data }) => {
        Object.keys(fileLoading).forEach((key) => {
          const fileStatus = data?.files?.find(
            (x) => x.file_name === key
          )?.search_status;
          if (fileStatus === "searchable" || fileStatus === "not_searchable") {
            setFileLoading(({ [key]: _, ...rest }) => rest);
          }
          if (fileStatus === "not_searchable") {
            setSelectedFile((prevFiles) =>
              prevFiles.filter((x) => x.name !== key)
            );
          }
        });
      },
    });
  };

  const uploadMediaHelper = (data, files) => {
    data.forEach((filedata, index) => {
      const object = {
        ...filedata,
        file: files[index],
      };
      delete object.name;
      delete object.url;
      const payload = convertObjectToFormData(object);
      uploadMedia({
        url: filedata.url,
        payload,
      });
    });
  };

  const generateUrlHelper = (files) => {
    const payload = {
      resource: "data",
      method: "chat_upload",
      details: {
        db_type: selectedProject.database,
        project_id: selectedProject.id,
        session_id: selectedChat ?? sessionId,
        files: files.map((item) => {
          return { file_name: item.name };
        }),
      },
    };
    if (selectedQuery) payload.details["query_id"] = selectedQuery;
    generateUrl({
      payload,
      promptMedia: true,
      success: (data) => {
        setSelectedQuery(data.queryId);
        uploadMediaHelper(data.files, files);
        setSelectedFile((prevFiles) =>
          prevFiles.filter((x) => data.files.some((y) => y.name === x.name))
        );
        setFileLoading((prevLoading) => {
          const temp = { ...prevLoading };
          Object.keys(prevLoading).forEach((key) => {
            if (!data.files.some((x) => x.name === key)) {
              delete temp[key];
            }
          });
          return temp;
        });
      },
      error: () => {
        setSelectedFile([]);
        setFileLoading({});
      },
    });
  };

  const stopResponseHelper = (data, closeConnection = true) => {
    setLoading(false);
    if (closeConnection) getWebSocket().close();
    if (messageHistory.current) messageHistory.current["isLoading"] = false;
    dispatch(recieveMessageRequest(data));
    messageHistory.current = null;
  };

  const generateMessagePayloadHelper = ({
    message = "",
    isError = false,
    isLoading = false,
  }) => {
    const payload = {
      isCompleted: false,
      isLoading: isError ? false : isLoading,
      isPrompt: true,
      model: selectedProject.modelLabel,
      message,
    };
    if (isError) payload["isError"] = true;
    return payload;
  };

  const sendImageMessageHelper = (message, images, session, queryId) => {
    const uploadedimages = images.filter((x) => x.type === "image");
    const uploadeddocs = images.filter((x) => x.type !== "image");
    const payload = {
      action: "queryV2",
      session_id: session ?? selectedChat ?? sessionId,
      query_id: queryId ?? selectedQuery,
      project_id: selectedProject.id,
      query: message,
      chat_upload: {
        images: uploadedimages.map((x) => x.name),
        docs: uploadeddocs.map((x) => x.name),
      },
    };
    if (uploadedimages.length >= 1) {
      payload["request_type"] = "vision";
      payload["endpoint"] = "vision";
    }
    if (MESSAGE_TYPE_JSON) {
      payload["response_format"] = { type: "json" };
    }
    sendJsonMessage(payload);
    setSelectedQuery(null);
  };

  const sendMessageHelper = (message, session) => {
    startToastTimer();

    // create message payload
    const messagePayload = {
      action: "queryV2",
      session_id: session ?? selectedChat,
      project_id: selectedProject.id,
      query: message,
    };

    if (MESSAGE_TYPE_JSON) {
      messagePayload["response_format"] = { type: "json" };
      messagePayload.search_params = {
        ...messagePayload.search_params,
        output_fields: ["source_name", "page_number", "tags", "url"],
      };
    }

    // send message to server
    sendJsonMessage(messagePayload);
  };

  const createChatHelper = (message, images, queryId) => {
    const name = message.split("\n")[0];
    const chatName = `${name.slice(0, 30)}${name.length > 30 ? "..." : ""}`;
    const payload = {
      method: "create",
      details: {
        app_id: selectedProject.id,
        session_name: chatName,
      },
    };
    if (images.length >= 1) payload.details["session_id"] = sessionId;
    createChat({
      payload,
      success: (res) => {
        images.length >= 1
          ? sendImageMessageHelper(message, images, res?.id, queryId)
          : sendMessageHelper(message, res?.id);
        setSearchParams({ chat: res?.id });
      },
    });
  };

  const getMessagesHelper = () => {
    const payload = {
      method: "describe",
      details: {
        session_id: selectedChat,
        app_id: selectedProject.id,
      },
      item_limit: 30,
    };
    if (moreMessagessData) payload["next_page"] = moreMessagessData;
    getMessages({
      payload,
      otherkeys: generateMessagePayloadHelper({}),
      success: (data) => {
        const isReversed = data.messages?.messages?.length >= 8;
        setReversedScrolling(isReversed);
        setScrollToKey(messages?.[0]?.id);
        setmoreMessagesData(data?.nextPageData);
        if (!isReversed) scrollToBottom();
      },
    });
  };

  // HANDLERS
  const scrollToBottom = () => {
    messageBox.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = useCallback(
    (e) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const scrolledToTop = Math.abs(scrollTop) + clientHeight === scrollHeight;
      setinitLoad(false);
      if (!scrolledToTop) return;
      if (!moreMessagessData || messgesLoader) return;
      getMessagesHelper();
    },
    [getMessagesHelper, messgesLoader]
  );

  const setSelectedChatOptionHandler = (item) => {
    setselectedChatOption(item);
  };

  const selectFileHandler = (files) => {
    const newFileLoadingState = {};
    const promises = Array.from(files).map((file) => {
      const fileName = file.name;
      newFileLoadingState[fileName] = true;

      return new Promise((resolve, reject) => {
        const fileType = file.type;
        const reader = new FileReader();
        setFileLoading((prevLoading) => ({
          ...prevLoading,
          ...newFileLoadingState,
        }));

        reader.onload = (e) => {
          resolve({ type: "image", data: e.target.result, name: fileName });
        };
        reader.onerror = (e) => reject(e);

        if (fileType.includes("image")) {
          reader.readAsDataURL(file);
        } else {
          resolve({ type: "doc", data: file, name: fileName });
        }
      });
    });

    Promise.all(promises)
      .then((results) => {
        setSelectedFile((prevFiles) => [...prevFiles, ...results]);
        generateUrlHelper(files);
      })
      .catch((error) => {
        console.error("Error reading files:", error);
      });
  };

  const removeSelectedFileHandler = (file) => {
    const payload = {
      resource: "data",
      method: "delete_assets",
      details: {
        session_id: selectedChat ?? sessionId,
        query_id: selectedQuery,
        project_id: selectedProject.id,
        db_type: "opensearch",
        files: [{ file_name: file }],
      },
    };
    setSelectedFile((prevFiles) => prevFiles.filter((x) => x.name !== file));
    setFileLoading(({ [file]: _, ...rest }) => rest);
    deleteMedia({ payload });
  };

  const sendMessageHandler = (message, images, queryId) => {
    const promptImages = images || selectedFile;
    setSelectedFile([]);

    // save message to reducer
    const usermessage = {
      message,
      images: promptImages,
    };

    if (queryId || selectedQuery) {
      usermessage["queryId"] = queryId || selectedQuery;
    }

    dispatch(sendMessageRequest(usermessage));

    // save message to history
    const payload = generateMessagePayloadHelper({
      isLoading: true,
      images: promptImages,
    });
    messageHistory.current = payload;

    // scroll to bottom
    scrollToBottom();

    setLoading(true);
    if (!selectedChat) {
      createChatHelper(message, promptImages, queryId);
      return;
    }
    // send image message
    if (promptImages.length >= 1) {
      sendImageMessageHelper(message, promptImages, null, queryId);
      return;
    }
    // else send text message
    sendMessageHelper(message);
  };

  const startToastTimer = () => {
    clearToastTimer();
    toastTimerRef.current = setTimeout(() => {
      setShowToast(true);
    }, MESSAGE_TIMEOUT * 1000);
  };

  const clearToastTimer = () => {
    if (toastTimerRef.current) {
      clearTimeout(toastTimerRef.current);
      toastTimerRef.current = null;
    }
  };

  const stopResponseHandler = () => {
    stopResponseHelper(messageHistory.current);
  };

  // MANIPULATORS
  const manipulateJsonMessage = (lastMessage) => {
    const data = JSON.parse(lastMessage);
    const message = data?.response;
    if ("message" in data) return;

    // need to check if error is in data
    if ("error" in data) {
      stopResponseHelper(
        generateMessagePayloadHelper({ message: data.error, isError: true })
      );
      return;
    }
    if (message.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      messageHistory.current["sources"] = data?.metadata?.sources ?? [];
      messageHistory.current["id"] = data?.metadata?.query_id;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if ("message" in data) return;
    const updatedmessage = messageHistory.current.message.concat(message);
    messageHistory.current = generateMessagePayloadHelper({
      message: updatedmessage,
      isLoading: false,
    });
  };
  const manipulateMessage = (lastMessage) => {
    if (lastMessage.includes(`<EOS>`)) {
      setLoading(false);
      messageHistory.current["isCompleted"] = true;
      dispatch(recieveMessageRequest(messageHistory.current));
      messageHistory.current = null;
      return;
    }
    if (lastMessage.includes(`message":`)) return;
    const message = messageHistory.current.message.concat(lastMessage);
    messageHistory.current = generateMessagePayloadHelper({
      message,
      isLoading: false,
    });
  };

  // HOOKS
  useMemo(() => {
    if (selectedChat) return;
    setSessionId(generateGuid());
  }, [selectedChat]);

  useLayoutEffect(() => {
    if (!scrollToKey || messageWrapper.current.scrollTop > 0 || initLoad)
      return;
    itemRefs.current?.[scrollToKey]?.scrollIntoView();
  }, [scrollToKey]);

  useEffect(() => {
    if (!showToast) return;
    stopResponseHelper(
      generateMessagePayloadHelper({
        message: MESSAGE_TIMEOUT_ERROR,
        isError: true,
      })
    );
    setShowToast(false);
  }, [showToast]);

  useMemo(() => {
    if (!selectedChat) {
      dispatch(clearMessages());
      getWebSocket()?.close();
      return;
    }
    if (!lastMessage) return;
    clearToastTimer();
    // if (lastMessage.includes(`message":`)) {
    //   // navigateToLogin()
    //   return;
    // }

    // manipulate message based on type
    if (MESSAGE_TYPE_JSON) return manipulateJsonMessage(lastMessage.data);

    manipulateMessage(lastMessage.data);
  }, [lastMessage]);

  useEffect(() => {
    setinitLoad(true);
    setmoreMessagesData(null);
    if (isLoading) return;
    if (selectedChat) {
      getMessagesHelper();
      return;
    }
    dispatch(clearMessages());
  }, [selectedChat]);

  useEffect(() => {
    let interval;
    const handleMediaChange = () => {
      const pendingMedia = Object.keys(fileLoading).length;
      if (pendingMedia > 0) {
        interval = setInterval(() => {
          getUploadedMediaStatus(true);
        }, 2000); // 2 seconds
      }
    };
    handleMediaChange();
    return () => interval && clearInterval(interval);
  }, [fileLoading, selectedQuery]);

  // CUSTOM COMPONENT
  const PromptThumb = () => (
    <img className="logo" src={Images.LogoThumb} alt="" />
  );

  return (
    <div className={clsx("chat-box", selectedFile.length >= 1 && "selected")}>
      {selectedProject?.webInterface && (
        <Helmet>
          <title>{selectedProject?.webTitle}</title>
        </Helmet>
      )}
      <div
        className={clsx("messages-wrapper", reversedScrolling && "reversed")}
        ref={messageWrapper}
        onScroll={handleScroll}
      >
        {messgesLoader && !moreMessagessData ? (
          <Loader height="100%" />
        ) : (
          <>
            {reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
            {messages.length >= 1 ? (
              <div className="messages-list">
                {moreMessagessData && messgesLoader && (
                  <Loader
                    height="30px"
                    size={10}
                    style={{ position: "absolute", top: 10, width: "100%" }}
                  />
                )}
                {messages.map((message, index) => (
                  <MessageBox
                    selectedProject={selectedProject}
                    key={index}
                    data={message}
                    ref={(ref) => (itemRefs.current[message.id] = ref)}
                    isLoading={isLoading || chatLoader}
                    previousMessage={messages[index - 1]}
                    reGenerateMessage={sendMessageHandler}
                    promptThumb={<PromptThumb />}
                  />
                ))}
                {messageHistory.current && (
                  <MessageBox
                    data={messageHistory.current}
                    selectedProject={selectedProject}
                    scrollToBottom={scrollToBottom}
                    promptThumb={<PromptThumb />}
                  />
                )}
              </div>
            ) : (
              <SplashScreen sendMessage={sendMessageHandler} />
            )}
            {!reversedScrolling && (
              <div className="scroller-box" ref={messageBox} />
            )}
          </>
        )}
      </div>
      <div className="bottom-wrapper">
        <InputBox
          ref={messageWrapper}
          selectedProject={selectedProject}
          selectedFile={selectedFile}
          selectFile={selectFileHandler}
          fileLoader={fileLoading}
          removeSelectedFile={removeSelectedFileHandler}
          sendMessage={sendMessageHandler}
          stopResponse={stopResponseHandler}
          isLoading={isLoading || chatLoader}
          chatOption={selectedChatOption}
          setChatOption={setSelectedChatOptionHandler}
        />
      </div>
    </div>
  );
};

export default ChatBox;
