import { ALERT_TYPES } from "../constants";
import { toastAlert } from "../utils";

export const manipulateGenerateUrlFileData = (name, { url, fields }) => {
  let file = {
    name,
    url,
    key: fields.key ?? "",
    AWSAccessKeyId: fields.AWSAccessKeyId ?? "",
    "x-amz-security-token": fields["x-amz-security-token"] ?? "",
    policy: fields.policy ?? "",
    signature: fields.signature ?? "",
  };
  return file;
};
export const manipulateGenerateUrlData = (requesteddata, payload) => {
  const generatefiles = [];
  const files = payload?.files ?? [];
  files.forEach(({ file_name }) => {
    const file = requesteddata?.files?.[file_name];
    if (typeof file === "string") {
      toastAlert(`${file_name} type is invalid`, ALERT_TYPES.ERROR);
    } else {
      generatefiles.push(manipulateGenerateUrlFileData(file_name, file));
    }
  });
  return { files: generatefiles, urls: requesteddata.urls };
};
